.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.form-error {
  display: flex;
  min-width: 50px;
  border: 2px solid red;
  justify-content: center;
  color: red;
  margin-bottom: 2rem;
}