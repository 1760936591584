.login-container {
  display: flex;
  height: 100%;
  width: 100%;
  display: flex;
  background-image: url('../../../../public/assets/images/MainBackground.png');
  background-size: contain;

  .login-wrapper ,
  .security-message {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }

  .login-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;

    .logo-wrapper {
      width: 300px;
      height: 230px;
      background-image: url('../../../../public/assets/images/pfs-logo.jpeg');
      margin-bottom: 2rem;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .page-title {
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 2rem;
    }

    .login-form {
      width: 100%;
      padding: 2rem;

      .vertical-padded {
        padding: 1rem 0;
      }

      .no-border-btn {
        border: none;
      }

      .auth-button {
        color: #047D95;
      }
    }
  }

  .security-message {
    color: white;
    padding: 1rem 3rem;
    font-size: 14px;
    text-align: center;
  }
}

.link {
  font-weight: bolder;
  color: rgb(140, 198, 63);
  text-decoration: underline;
}