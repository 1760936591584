.sites {
  .headings {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    .flex {
      display: flex;
      gap: 1rem;
    }

    .add-site {
      margin: 1rem 0;
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
    }
  }

  .group-btn {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

}
