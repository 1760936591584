.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding: 1rem;
  background-color: white;

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: bolder;
      font-size: 3rem;
    }

    .tabs {
      display: flex;
      gap: 1rem;
      
      .tab-item {
        cursor: pointer;
        padding: 5px 10px;
        font-weight: bold;
        color: lightgray;

        &.selected,
        &:hover {
          color: black;
          background: lightgray;
        }

        option.selected {
          color: red;
        }
      }
    }
  }

}