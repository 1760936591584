.user-info-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 2rem 0;
  gap: 1rem;

  .error {
    color: red;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .form-error {
    display: flex;
    min-width: 50px;
    border: 2px solid red;
    justify-content: center;
    color: red;
    margin-bottom: 2rem;
  }
}